import React from 'react';
import { Link } from 'gatsby';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorPatryk as author } from 'data/authors';
import img from 'img/blog/livechat-integration/cover.png';
import img1 from 'img/blog/livechat-integration/1.png';
import img2 from 'img/blog/livechat-integration/2.png';

const Content = () => {
  return (
    <Post>
      <p>
        We’re taking our <strong>integration with LiveChat to the next level</strong>.{' '}
      </p>

      <p>
        From now on, it’ll be easier to connect LiveSession to a market-leading live chat solution
        and add context to each conversation you are having.
      </p>

      <p>
        With just a couple of clicks, you can add more context to every conversation you are having.
        Our LiveChat integration will add a link to the user’s session to each chat so that you can
        access it easily.
      </p>

      <img
        src={img1}
        alt="Enrich every chat with a link to the users session replay"
        title="Livechat integration"
      />

      <p>
        Get to know what the user you are talking to was doing on your website and enchant your
        visitors with excellent support:
      </p>

      <img
        src={img2}
        alt="Get to know what the user you are talking to was doing on your website"
        title="Livechat integration"
      />

      <p>
        Integrating LiveChat with LiveSession makes it easier to provide customer support and debug
        technical issues.
      </p>

      <p>
        Start using our LiveChat integration now,{' '}
        <Link to="/integrations/livechat/">visit LiveChat in Integrations Marketplace</Link>.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Integration with LiveChat',
  url: '/blog/integration-with-livechat/',
  description: `Enrich every chat with a link to the user&apos;s session replay.`,
  author,
  img,
  imgSocial: img,
  date: '2021-04-16',
  category: '',
  group: 'updates',
  timeToRead: 2,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
